import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { calculateClientWidth } from '@atnd/utils';
import { TableCell } from '@mui/material';
import { memo, useRef } from 'react';
import { isDefined } from 'remeda';
import { healthCheckCellDisplayStyles, healthCheckThStyles } from './constants/tableStyles';
/*
 * 検査項目, 基準値, 単位のヘッダー
 * 横にスクロールした際に固定される
 */
const HealthCheckRowHeaders = memo(({ checklistLabel, max, min, unit }) => {
    const firstRowThRef = useRef(null);
    const secondRowThRef = useRef(null);
    const firstRowThWidth = calculateClientWidth(firstRowThRef);
    const secondRowThWidth = calculateClientWidth(secondRowThRef);
    const rowHealthCheckThStyles = {
        backgroundColor: '#ECECED',
        zIndex: 2,
    };
    const getStandardValue = () => {
        const rangeParts = [];
        if (isDefined(min)) {
            rangeParts.push(min);
        }
        if (isDefined(min) || isDefined(max)) {
            rangeParts.push(' ~ ');
        }
        if (isDefined(max)) {
            rangeParts.push(max);
        }
        return rangeParts.length ? rangeParts.join('') : '-';
    };
    return (_jsxs(_Fragment, { children: [_jsx(TableCell, { ref: firstRowThRef, component: "td", scope: "row", sx: { ...healthCheckThStyles, ...rowHealthCheckThStyles, left: 0 }, children: checklistLabel }), _jsx(TableCell, { ref: secondRowThRef, component: "td", sx: {
                    ...healthCheckThStyles,
                    ...healthCheckCellDisplayStyles,
                    ...rowHealthCheckThStyles,
                    left: firstRowThWidth,
                    textAlign: 'center',
                }, children: getStandardValue() }), _jsx(TableCell, { component: "td", sx: {
                    ...healthCheckThStyles,
                    ...healthCheckCellDisplayStyles,
                    ...rowHealthCheckThStyles,
                    left: firstRowThWidth + secondRowThWidth,
                }, children: unit })] }));
});
HealthCheckRowHeaders.displayName = 'HealthCheckRowHeaders';
export { HealthCheckRowHeaders };
