"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const aspida_1 = require("aspida");
const api = ({ baseURL, fetch }) => {
    const prefix = (baseURL === undefined ? 'https://kotobuk.jp/api/v0' : baseURL).replace(/\/$/, '');
    const PATH0 = '/atnd-user';
    const PATH1 = '/employees';
    const PATH2 = '/employees/batch';
    const PATH3 = '/healthcheck/files';
    const PATH4 = '/healthcheck/files/upload';
    const PATH5 = '/healthcheck/ocr';
    const PATH6 = '/healthcheck/record';
    const PATH7 = '/healthcheck/standard';
    const PATH8 = '/healthcheck-file';
    const PATH9 = '/records';
    const PATH10 = '/login-staff';
    const PATH11 = '/organization';
    const PATH12 = '/refresh-staff-token';
    const PATH13 = '/staffs';
    const PATH14 = '/user';
    const PATH15 = '/healthcheck/records';
    const GET = 'GET';
    const POST = 'POST';
    const DELETE = 'DELETE';
    const PATCH = 'PATCH';
    return {
        atnd_user: {
            _atnd_user_id: (val1) => {
                const prefix1 = `${PATH0}/${val1}`;
                return {
                    /**
                     * ユーザーを取得する。主にAtnd側からKotobukのユーザー情報を取得する際に本APIを使用する。
                     * @returns Success
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * ユーザーを取得する。主にAtnd側からKotobukのユーザー情報を取得する際に本APIを使用する。
                     * @returns Success
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
        },
        employees: {
            _employee_id: (val1) => {
                const prefix1 = `${PATH1}/${val1}`;
                return {
                    /**
                     * 特定の従業員を取得する。
                     * @returns Success
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 特定の従業員を取得する。
                     * @returns Success
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * 特定の従業員を更新する。
                     * @returns Success
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * 特定の従業員を更新する。
                     * @returns Success
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    /**
                     * 特定の従業員を削除する。
                     */
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    /**
                     * 特定の従業員を削除する。
                     */
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            batch: {
                /**
                 * 複数の従業員を一括で登録する。
                 * @returns Created
                 */
                post: (option) => fetch(prefix, PATH2, POST, option).json(),
                /**
                 * 複数の従業員を一括で登録する。
                 * @returns Created
                 */
                $post: (option) => fetch(prefix, PATH2, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH2}`,
            },
            /**
             * 従業員の一覧を取得する。
             * @returns Success
             */
            get: (option) => fetch(prefix, PATH1, GET, option).json(),
            /**
             * 従業員の一覧を取得する。
             * @returns Success
             */
            $get: (option) => fetch(prefix, PATH1, GET, option).json().then(r => r.body),
            /**
             * 新しい従業員を作成する。
             * @returns Created
             */
            post: (option) => fetch(prefix, PATH1, POST, option).json(),
            /**
             * 新しい従業員を作成する。
             * @returns Created
             */
            $post: (option) => fetch(prefix, PATH1, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH1}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        healthcheck: {
            files: {
                _healthcheck_file_id: (val2) => {
                    const prefix2 = `${PATH3}/${val2}`;
                    return {
                        /**
                         * 特定の健康診断ファイルを取得する。
                         * @returns Success
                         */
                        get: (option) => fetch(prefix, prefix2, GET, option).json(),
                        /**
                         * 特定の健康診断ファイルを取得する。
                         * @returns Success
                         */
                        $get: (option) => fetch(prefix, prefix2, GET, option).json().then(r => r.body),
                        /**
                         * 特定の健康診断ファイルを削除する。
                         */
                        delete: (option) => fetch(prefix, prefix2, DELETE, option).send(),
                        /**
                         * 特定の健康診断ファイルを削除する。
                         */
                        $delete: (option) => fetch(prefix, prefix2, DELETE, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix2}`,
                    };
                },
                upload: {
                    /**
                     * csv, png, jpg, pdf形式の健康診断結果ファイルをアップロードする。csvの場合は、DBに登録する。 png, jpg, pdfの場合はOCRを実行のためKotobukの分析APIを呼び出す。
                     * @returns Successfully uploaded
                     */
                    post: (option) => fetch(prefix, PATH4, POST, option, 'FormData').json(),
                    /**
                     * csv, png, jpg, pdf形式の健康診断結果ファイルをアップロードする。csvの場合は、DBに登録する。 png, jpg, pdfの場合はOCRを実行のためKotobukの分析APIを呼び出す。
                     * @returns Successfully uploaded
                     */
                    $post: (option) => fetch(prefix, PATH4, POST, option, 'FormData').json().then(r => r.body),
                    $path: () => `${prefix}${PATH4}`,
                },
                /**
                 * 健康診断ファイルの一覧を取得する。
                 * @returns Success
                 */
                get: (option) => fetch(prefix, PATH3, GET, option).json(),
                /**
                 * 健康診断ファイルの一覧を取得する。
                 * @returns Success
                 */
                $get: (option) => fetch(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: (option) => `${prefix}${PATH3}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
            },
            ocr: {
                _healthcheck_file_id: (val2) => {
                    const prefix2 = `${PATH5}/${val2}`;
                    return {
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を取得する。
                         * @returns Success
                         */
                        get: (option) => fetch(prefix, prefix2, GET, option).json(),
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を取得する。
                         * @returns Success
                         */
                        $get: (option) => fetch(prefix, prefix2, GET, option).json().then(r => r.body),
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を更新する。
                         * @returns Success
                         */
                        patch: (option) => fetch(prefix, prefix2, PATCH, option).json(),
                        /**
                         * 健康診断結果ファイルのOCRの実行結果を更新する。
                         * @returns Success
                         */
                        $patch: (option) => fetch(prefix, prefix2, PATCH, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix2}`,
                    };
                },
            },
            record: {
                _healthcheck_record_id: (val2) => {
                    const prefix2 = `${PATH6}/${val2}`;
                    return {
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を取得する。
                         * @returns Success
                         */
                        get: (option) => fetch(prefix, prefix2, GET, option).json(),
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を取得する。
                         * @returns Success
                         */
                        $get: (option) => fetch(prefix, prefix2, GET, option).json().then(r => r.body),
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を更新する。
                         * @returns Success
                         */
                        patch: (option) => fetch(prefix, prefix2, PATCH, option).json(),
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を更新する。
                         * @returns Success
                         */
                        $patch: (option) => fetch(prefix, prefix2, PATCH, option).json().then(r => r.body),
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を削除する。
                         */
                        delete: (option) => fetch(prefix, prefix2, DELETE, option).send(),
                        /**
                         * 特定のユーザーのhealthcheck_idに指定した健康診断結果を削除する。
                         */
                        $delete: (option) => fetch(prefix, prefix2, DELETE, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix2}`,
                    };
                },
                /**
                 * 特定のユーザーの健康診断結果を登録する。
                 * @returns Success
                 */
                post: (option) => fetch(prefix, PATH6, POST, option).json(),
                /**
                 * 特定のユーザーの健康診断結果を登録する。
                 * @returns Success
                 */
                $post: (option) => fetch(prefix, PATH6, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH6}`,
            },
            standard: {
                /**
                 * 健康診断の各項目の基準値を取得する。
                 * @returns Success
                 */
                get: (option) => fetch(prefix, PATH7, GET, option).json(),
                /**
                 * 健康診断の各項目の基準値を取得する。
                 * @returns Success
                 */
                $get: (option) => fetch(prefix, PATH7, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH7}`,
            },
        },
        healthcheck_file: {
            _healthcheck_file_id: (val1) => {
                const prefix1 = `${PATH8}/${val1}`;
                return {
                    records: {
                        /**
                         * 特定の健康診断ファイルに紐づく健康診断データを取得する。
                         * @returns Success
                         */
                        get: (option) => fetch(prefix, `${prefix1}${PATH9}`, GET, option).json(),
                        /**
                         * 特定の健康診断ファイルに紐づく健康診断データを取得する。
                         * @returns Success
                         */
                        $get: (option) => fetch(prefix, `${prefix1}${PATH9}`, GET, option).json().then(r => r.body),
                        $path: (option) => `${prefix}${prefix1}${PATH9}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                    },
                };
            },
        },
        login_staff: {
            /**
             * Kotobukスタッフログイン
             * @returns Success
             */
            post: (option) => fetch(prefix, PATH10, POST, option).json(),
            /**
             * Kotobukスタッフログイン
             * @returns Success
             */
            $post: (option) => fetch(prefix, PATH10, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH10}`,
        },
        organization: {
            _organization_id: (val1) => {
                const prefix1 = `${PATH11}/${val1}`;
                return {
                    /**
                     * 特定の企業を取得する。
                     * @returns Success
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 特定の企業を取得する。
                     * @returns Success
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * 特定の企業を更新する。
                     * @returns Success
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * 特定の企業を更新する。
                     * @returns Success
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    /**
                     * 特定の企業を削除する。
                     */
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    /**
                     * 特定の企業を削除する。
                     */
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * 新しい企業を作成する。
             * @returns Created
             */
            post: (option) => fetch(prefix, PATH11, POST, option).json(),
            /**
             * 新しい企業を作成する。
             * @returns Created
             */
            $post: (option) => fetch(prefix, PATH11, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH11}`,
        },
        refresh_staff_token: {
            /**
             * Kotobukスタッフのアクセストークンを再取得する
             * @returns Success
             */
            post: (option) => fetch(prefix, PATH12, POST, option).json(),
            /**
             * Kotobukスタッフのアクセストークンを再取得する
             * @returns Success
             */
            $post: (option) => fetch(prefix, PATH12, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH12}`,
        },
        staffs: {
            _staff_id: (val1) => {
                const prefix1 = `${PATH13}/${val1}`;
                return {
                    /**
                     * 特定のスタッフを取得する。
                     * @returns Success
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * 特定のスタッフを取得する。
                     * @returns Success
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * 特定のスタッフを更新する。
                     * @returns Success
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * 特定のスタッフを更新する。
                     * @returns Success
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    /**
                     * 特定のスタッフを削除する。
                     */
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    /**
                     * 特定のスタッフを削除する。
                     */
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * スタッフの一覧を取得する。
             * @returns Success
             */
            get: (option) => fetch(prefix, PATH13, GET, option).json(),
            /**
             * スタッフの一覧を取得する。
             * @returns Success
             */
            $get: (option) => fetch(prefix, PATH13, GET, option).json().then(r => r.body),
            /**
             * 新しいスタッフを作成する。
             * @returns Created
             */
            post: (option) => fetch(prefix, PATH13, POST, option).json(),
            /**
             * 新しいスタッフを作成する。
             * @returns Created
             */
            $post: (option) => fetch(prefix, PATH13, POST, option).json().then(r => r.body),
            $path: (option) => `${prefix}${PATH13}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
        },
        user: {
            _user_id: (val1) => {
                const prefix1 = `${PATH14}/${val1}`;
                return {
                    healthcheck: {
                        records: {
                            /**
                             * 特定のユーザーの健康診断履歴を取得する。
                             * @returns Success
                             */
                            get: (option) => fetch(prefix, `${prefix1}${PATH15}`, GET, option).json(),
                            /**
                             * 特定のユーザーの健康診断履歴を取得する。
                             * @returns Success
                             */
                            $get: (option) => fetch(prefix, `${prefix1}${PATH15}`, GET, option).json().then(r => r.body),
                            $path: (option) => `${prefix}${prefix1}${PATH15}${option && option.query ? `?${(0, aspida_1.dataToURLString)(option.query)}` : ''}`,
                        },
                    },
                    /**
                     * ユーザーを取得する。
                     * @returns Success
                     */
                    get: (option) => fetch(prefix, prefix1, GET, option).json(),
                    /**
                     * ユーザーを取得する。
                     * @returns Success
                     */
                    $get: (option) => fetch(prefix, prefix1, GET, option).json().then(r => r.body),
                    /**
                     * ユーザーを更新する。
                     * @returns Success
                     */
                    patch: (option) => fetch(prefix, prefix1, PATCH, option).json(),
                    /**
                     * ユーザーを更新する。
                     * @returns Success
                     */
                    $patch: (option) => fetch(prefix, prefix1, PATCH, option).json().then(r => r.body),
                    /**
                     * ユーザーを削除する。
                     */
                    delete: (option) => fetch(prefix, prefix1, DELETE, option).send(),
                    /**
                     * ユーザーを削除する。
                     */
                    $delete: (option) => fetch(prefix, prefix1, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix1}`,
                };
            },
            /**
             * ユーザーを登録する。
             * @returns Success
             */
            post: (option) => fetch(prefix, PATH14, POST, option).json(),
            /**
             * ユーザーを登録する。
             * @returns Success
             */
            $post: (option) => fetch(prefix, PATH14, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH14}`,
        },
    };
};
exports.default = api;
