import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { kotobukApi } from '@atnd/api-client-kotobuk';
import { isNumber, isString } from 'remeda';
import { healthCheckStandardAtom } from '../stores/healthCheckStandardAtom';
export const isNumberHealthcheckParam = (param) => {
    return param?.type === 'number';
};
const isBooleanHealthcheckParam = (param) => {
    return param?.type === 'boolean';
};
const isLevelChoiceHealthcheckParam = (param) => {
    return param?.type === 'level_choice';
};
export const useHealthCheckStandard = ({ sex }) => {
    const [healthCheckStandard, setHealthCheckStandard] = useAtom(healthCheckStandardAtom);
    const fetchHealthCheckStandard = useCallback(async () => {
        if (healthCheckStandard)
            return;
        try {
            const response = await kotobukApi.healthcheck.standard.$get();
            setHealthCheckStandard(response);
        }
        catch (error) {
            console.error('Failed to fetch health check standard:', error);
        }
    }, [setHealthCheckStandard, healthCheckStandard]);
    useEffect(() => {
        fetchHealthCheckStandard();
    }, [fetchHealthCheckStandard]);
    const isAbnormal = (key, value, param) => {
        if (!param) {
            return null;
        }
        const invalidTypeMessage = `HealthcheckParamのtypeが不正です: ${key}: ${value}`;
        switch (param.type) {
            case 'number':
                if (!isNumberHealthcheckParam(param)) {
                    return null;
                }
                if (isNumber(value)) {
                    if (value < param.low) {
                        return 'low';
                    }
                    if (value > param.high) {
                        return 'high';
                    }
                    return null;
                }
                return null;
            case 'boolean':
                if (!isBooleanHealthcheckParam(param)) {
                    throw new Error(invalidTypeMessage);
                }
                return value === true ? 'abnormal' : null;
            case 'level_choice':
                if (!isLevelChoiceHealthcheckParam(param)) {
                    throw new Error(invalidTypeMessage);
                }
                if (isString(value)) {
                    return param.standard.includes(value) ? 'abnormal' : null;
                }
                return null;
            default:
                throw new Error('HealthcheckParamが不正です');
        }
    };
    const healthCheckStandardFlatten = healthCheckStandard
        ? {
            ...healthCheckStandard,
            abdominal_circumference: healthCheckStandard.abdominal_circumference[sex],
        }
        : undefined;
    const isKeyOfHealthcheckStandard = (key) => {
        return !!healthCheckStandardFlatten && key in healthCheckStandardFlatten;
    };
    return { healthCheckStandardFlatten, isAbnormal, isKeyOfHealthcheckStandard };
};
