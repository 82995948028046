import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDate } from '@atnd/dayjs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { TableCell, Typography } from '@mui/material';
import { Flex, FlexCol } from '../mui/Box';
export const UploadedAtCell = ({ uploadedAt, uploader_category, }) => {
    return (_jsx(TableCell, { children: _jsxs(Flex, { gap: 1, alignItems: "center", children: [_jsx(UploadCategoryIcon, { uploader_category: uploader_category }), _jsx(Typography, { variant: "caption", color: "textSecondary", children: formatDate(uploadedAt) })] }) }));
};
const UploadCategoryIcon = ({ uploader_category, }) => {
    return (_jsx(FlexCol, { children: uploader_category === 'company' ? (_jsx(BusinessIcon, { color: "action" })) : uploader_category === 'clinic' ? (_jsx(LocalHospitalIcon, { color: "error" })) : uploader_category === 'patient' ? (_jsx(AccountCircleIcon, { color: "primary" })) : (_jsx(_Fragment, {})) }));
};
