import { useCallback, useEffect, useState } from 'react';
import { kotobukApi } from '@atnd/api-client-kotobuk';
export const useHealthCheck = (userId) => {
    const [healthCheck, setHealthCheck] = useState({ records: [] });
    const fetchUserHealthCheck = useCallback(async (userId) => {
        try {
            const response = await kotobukApi.user._user_id(userId).healthcheck.records.$get({
                query: {
                    page: 1,
                    per_page: 100,
                },
            });
            setHealthCheck({ records: response.results });
        }
        catch (error) {
            console.error('Failed to fetch health check:', error);
        }
    }, []);
    useEffect(() => {
        fetchUserHealthCheck(userId);
    }, [userId, fetchUserHealthCheck]);
    return { healthCheck };
};
