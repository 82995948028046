import { kotobukApi } from '@atnd/api-client-kotobuk';
import { useGetKotobukUser } from './useGetKotobukUser';
import { useSnackbar } from '../../features/notification/useSnackbar';
export const useUploadHealthCheckFile = ({ atndUser, file, onUploaded, surveyUserId: initialSurveyUserId, uploadType, uploaderId, }) => {
    const { openSnackbar } = useSnackbar();
    const { getKotobukUser } = useGetKotobukUser();
    // TODO: 連携機能実装時に別Hookに切り出す
    const createOrGetUser = async () => {
        if (!atndUser?.atnd_user_id)
            return undefined;
        try {
            const user = await getKotobukUser(atndUser.atnd_user_id);
            return user;
        }
        catch {
            return kotobukApi.user
                .$post({
                body: atndUser,
            })
                .catch(() => {
                openSnackbar('kotobukのユーザー作成に失敗しました', 'error');
                return undefined;
            });
        }
    };
    const uploadFile = async ({ userId, surveyUserId, }) => {
        if (!uploaderId || !file) {
            openSnackbar('必要な情報が不足しています。', 'error');
            return;
        }
        try {
            await kotobukApi.healthcheck.files.upload.$post({
                body: {
                    user_id: userId,
                    file,
                    uploader_category: 'clinic',
                    uploader_id: uploaderId,
                    survey_user_id: surveyUserId,
                },
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            });
            onUploaded?.();
            openSnackbar('アップロードが完了しました', 'success');
        }
        catch (error) {
            console.error(error);
            openSnackbar('アップロードに失敗しました', 'error');
        }
    };
    const handleUpload = async (surveyUserId = initialSurveyUserId) => {
        if (uploadType === 'pdf' && atndUser) {
            const user = await createOrGetUser();
            if (user) {
                await uploadFile({ userId: user.id, surveyUserId });
            }
        }
        else if (uploadType !== 'pdf') {
            await uploadFile({ userId: undefined, surveyUserId });
        }
    };
    return {
        handleUploadHealthCheckFile: handleUpload,
    };
};
