import { jsx as _jsx } from "react/jsx-runtime";
import { TableCell } from '@mui/material';
import { forwardRef } from 'react';
import { statusColors } from './constants/statusColor';
export const HCTd = forwardRef(({ healthCheckParam, isAbnormal, name, options, value }, ref) => {
    const status = isAbnormal(name, value, healthCheckParam);
    const backgroundColor = getStatusColor(status);
    const displayValue = options ? options.find((option) => option.value === value)?.label : value;
    return (_jsx(TableCell, { ref: ref, component: "td", sx: {
            minWidth: '100px',
            whiteSpace: 'nowrap',
            backgroundColor,
        }, children: displayValue }));
});
HCTd.displayName = 'HCTd';
const getStatusColor = (status) => {
    switch (status) {
        case 'high':
        case 'abnormal':
            return statusColors.HIGH;
        case 'low':
            return statusColors.LOW;
        default:
            return 'inherit';
    }
};
